import React from 'react'
import { useState } from 'react'
import styled from 'styled-components'

const ProductShowcase = (props) => {
    const [activeProductIndex, setActiveProductIndex] = useState(0);

    const prevProduct = () => {
        if (activeProductIndex > 0) {
            setActiveProductIndex(activeProductIndex - 1)
        }
    }

    const nextProduct = () => {
        if (activeProductIndex < props.products.length - 1) {
            setActiveProductIndex(activeProductIndex + 1)
        }
    }

  return (
    <>
    {props.products && (
      <ProductsSection className="products">
          <h3 className="subtitle">Productos</h3>
          <div className="flechas">
            <ProductPrev onClick={prevProduct}>
              <svg x="0px" y="0px" viewBox="0 0 476.213 476.213">
                  <polygon points="345.606,107.5 324.394,128.713 418.787,223.107 0,223.107 0,253.107 418.787,253.107 324.394,347.5 345.606,368.713 476.213,238.106"/>
              </svg>
            </ProductPrev>
            <ProductNext onClick={nextProduct}>
              <svg x="0px" y="0px" viewBox="0 0 476.213 476.213">
                  <polygon points="345.606,107.5 324.394,128.713 418.787,223.107 0,223.107 0,253.107 418.787,253.107 324.394,347.5 345.606,368.713 476.213,238.106"/>
              </svg>
            </ProductNext>
          </div>
          
          <Product>
            <ProductDescription>
              <h4>{props.products[activeProductIndex]?.name}</h4>
              {props.products[activeProductIndex]?.items.map((item, index) => {
                  return <li key={index}>{item}<br/></li>
              })}
            </ProductDescription>
            <ProductImage>
              <img
                src={props.products[activeProductIndex]?.img}
                alt={props.products[activeProductIndex]?.name}
              />
            </ProductImage>
          </Product>
          <RadiosContainer>
            {props.products.map((item, index) => {
              return (
                <li 
                  className={activeProductIndex === index ? "radio_item active" : "radio_item"}
                  key={item.id}
                  onClick={() => setActiveProductIndex(item.id - 1)}>
                </li>
              )
            })}
          </RadiosContainer>
      </ProductsSection>
      )}
      </>
  )
}

export default ProductShowcase

const ProductsSection = styled.div`
  display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-top: 60px;
    padding: 0 8%;
    .flechas{
        position: absolute;
        top: 40%;
        width: 90%;
        display: flex;
        justify-content: space-between;
        @media screen and (max-width: 768px){
          top: 200px;
      }
    }
`

const Product = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 100px;
  h4{
      font-size: 2rem;
      text-transform: uppercase;
      font-weight: 700;
      margin: 0;
      margin-bottom: 16px;
      line-height: 2rem;
      max-width: 16ch;
  }

  @keyframes rollout {
      0% { 
          transform: translateY(-80px);
          opacity: 0%;
      }
      100% { 
          transform: translateY(0); 
          opacity: 100%;
      }
    }
    animation: rollout 0.4s;

  @media screen and (max-width: 768px){
    flex-direction: column-reverse;
    padding-left: 0;
  }
`

const ProductDescription = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 330px;
  li{
    font-size: 1.2rem;
    color: rgb(119, 119, 119);
  }
  @media screen and (max-width: 768px){
    text-align: center;
    align-items: center;
    li{
      font-size: 1rem;
    }
  }
`

const ProductImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 330px;
  img{
    height: 310px;
  }
  @media screen and (max-width: 768px){
    justify-content: center;
    align-items: center;
    img{
      height: 220px;
    }
  }
`

const RadiosContainer = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  height: 12px;
  padding: 0;
  .active{
      background-color: #0348a1;
      width: 10px; height: 10px;
  }
  li{
      width: 10px; height: 10px;
      overflow: hidden;
      border-radius: 80px;
      border: solid 1.5px #0348a1;
      cursor: pointer;
      transition: 0.2s;
      &:not(:first-child){
          margin-left: 8px;
      }
      
  }
`

const ProductPrev = styled.button`
  width: 54px;
  height: 54px;
  border-radius: 100px;
  padding: 10px 0px 5px 0px;
  margin-right: 0px!important;
  svg{
    transform: rotate(180deg);
    width: 28px;
    fill: white;
  }
`

const ProductNext = styled.button`
  width: 54px;
  height: 54px;
  border-radius: 100px;
  margin-left: 60px;
  padding: 10px 0px 5px 0px;
  svg{
    width: 28px;
    fill: white;
  }
`
