import React from 'react'
import Slider from "react-slick";
import styled from "styled-components";

export default function CustomSlider(props) {
  const shouldRender = props.items.length >= props.itemCount;
  const numberOfRows = (Math.floor(props.items.length / props.itemCount));

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: props.itemCount,
    slidesToScroll: 1,
    autoplay: true,
    speed: 6000,
    autoplaySpeed: 0,
    cssEase: "linear",
    pauseOnHover: false,
    rows: numberOfRows,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 5,
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
        }
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          rows: props.mobileRows
        }
      },
    ]
  };

  return (
    shouldRender && (
      <SliderContainer>
      <h3 className="subtitle">{props.title}</h3>
      <Slider {...settings}>
        {
          props.items.map((item, index) => 
          <SliderItem>
              <img 
                style={{maxWidth:165+"px"}} 
                src={item.img} 
                alt={item.name} 
                />
            </SliderItem> 
            )
          }
      </Slider>
    </SliderContainer>
    )
  )
}

const SliderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  padding-top: 40px;
  padding-bottom: 40px;
  h3{
      font-size: 2.4rem;
      align-self: center;
      text-align: center;
      @media screen and (max-width: 768px){
        font-size: 2rem;
        max-width: 18ch;
      }
  }
`

const SliderItem = styled.div`
  height: 140px;
  width: 160px;
  display: flex!important;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  img{
      width: 50%;
  }
  @media screen and (max-width: 768px){
    height: 130px;
    width: 240px;
    margin: 0;
    img{
      width: 60%;
    }
  }
`
