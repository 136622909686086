import './navbar.css' 
import $ from 'jquery'
import whatsappIcon from "./whatsappIcon.png"

function Navbar(props) {


    const navBtnHandler = (e, button) => {
        e.preventDefault();
        $([document.documentElement, document.body]).animate({
            scrollTop: $(`.${button}`).offset().top - 82,
            easing : "easeIn"
        }, 1800);
    }


    $(document).ready(function() {
        // Navbar
        const navbar = $(".navbar");

        $(window).on('scroll', function () {
            $(".navbar .nav").removeClass("show_nav");

            if($(window).scrollTop() < 60) {
                navbar.css("box-shadow", "none");
            }
            if ($(window).scrollTop() > 60) {
                navbar.css("box-shadow", "0 0 10px 0 hsla(0, 0%, 9%, 0.3)");
            }
        });

        $(".mobile_nav_item").click(function() {
            $(".navbar .nav").removeClass("show_nav");
        });

        $(".menu_btn").click(function() {
            $(".navbar .nav").toggleClass("show_nav");
        });

    });

    return (
        <div className="navbar" style={{backgroundColor : props.bgColor}}>
            <div className="nav_main" style={{backgroundColor : props.bgColor}}>
                <a onClick={(e) => navBtnHandler(e, "hero_bg")} className="logo" href="#Inicio"><img src={props.logo} alt="ProviArg Logo"></img></a>
                
                <div className="menu_btn">
                    <svg width="100%" heigh="100%" x="0px" y="0px" viewBox="0 0 384 384">
                        <rect x="0" y="277.333" width="384" height="42.667"/>
                        <rect x="0" y="170.667" width="384" height="42.667"/>
                        <rect x="0" y="64" width="384" height="42.667"/>
                    </svg>
                </div>
            </div>
      
            <div className="nav">
                <a onClick={(e) => navBtnHandler(e, "about")} className="mobile_nav_item" href="#Nosotros" style={{color : props.fontColor}} id="about_btn">Nosotros</a>
                <a onClick={(e) => navBtnHandler(e, "products")} className="mobile_nav_item" href="#Productos" style={{color : props.fontColor}} id="products_btn">Productos</a>
                <a onClick={(e) => navBtnHandler(e, "contact")} className="mobile_nav_item" href="#Contacto" style={{color : props.fontColor}} id="contact_btn">Contacto</a>
                <a target="_blank" rel="noreferrer" href="https://wa.me/543476504444" className="phone nav_phone">
                    <img src={whatsappIcon} style={{width:24+"px"}}></img>
                    <p>Contacto por WhatsApp</p>
                </a>
            </div>
        </div>

    );
}


export default Navbar;