import React from "react";
import { useContentful } from "../hooks/useContentful";
import Navbar from '../components/Navbar/navbar'
import Form from '../components/Form/form'
import $ from 'jquery'
import CustomSlider from "../components/CustomSlider";
import ProductShowcase from "../components/ProductShowcase";

const Homepage = () => {
  const { data, actions } = useContentful();

    const goToProducts = (e) => {
        e.preventDefault();
        $([document.documentElement, document.body]).animate({
            scrollTop: $(`.products`).offset().top - 80,
            easing : "easeIn"
        }, 1800);
    }

    return (
        <div>
            {/* <LoadingScreen /> */}
            <Navbar 
                logo="../img/logo_navbar.png"
                bgColor="#fff"
                fontColor="#0348a1"
            />

            <div className="hero">
                <div className="hero_bg"></div>
                <img id="logo" src="../img/logo_hero.png" alt="ProviArg Logo"></img>
            </div>

            <div className="about section">
                    <div className="about_text">
                        <h3 className="subtitle">Sobre nosotros</h3>
                        <p>Somos una empresa dedicada a la provisión de insumos y repuestos para la industria, PYME y comercios.
                        <br></br><br></br>
                        Contamos con el respaldo de empresas de primer nivel brindando asespramiento técnico y soluciones a los inconvenientes en equipos.
                        <br></br><br></br>
                        También brindamos la posibilidad de crear ambientes de trabajo seguros a través de nuestros productos.
                        </p>
                        <button onClick={(e) => goToProducts(e)}>Qué proveemos</button>
                    </div>
                    <img className="about_photo" src="img/fabrica.jpg" alt="fabrica" />
            </div>
          {data.companies.brands ?
           (
            <CustomSlider 
              items={data.companies.brands} 
              itemCount={7}
              mobileRows={3}
              title="Marcas que trabajamos"
            />
            ) : <></>}

            {data.products ? (
              <ProductShowcase products={data.products}/>
            ) : ("")
            }

            <div className="catalogue">
                <div className="catalogue_container">
                    <div className="catalogue_inner">
                        <h3 className="subtitle">Catálogo</h3>
                        <img src="./img/catalogo.png"></img>
                        <div className="catalogue_btn_container">
                            <button disabled={!data.catalogue.url} className="catalogue_btn" onClick={() => window.open(data.catalogue.url,'_blank')}>
                             Ver Online
                            </button>
                            <button disabled={!data.catalogue.blob} className="catalogue_btn" onClick={() => actions.downloadCatalogue("Catalogo ProviARG")}>
                              Descargar
                            </button>
                        </div>
                    </div>
                </div>
            </div>

              <CustomSlider 
                  items={data.companies.buyers} 
                  itemCount={6}
                  mobileRows={3}
                  title="Compañías que confían en nosotros"
              />

            <div className="contact section">
                <h3 className="subtitle">Contacto</h3>
                <div className="contact_inner">
                    <div className="contact_info">
                        <div className="contact_info_item">
                            <img src="icons/phone.png" alt="phone number icon"></img>
                            <div><p>+54 9 3476-504444</p></div>
                        </div>
                        <div className="contact_info_item">
                            <img src="icons/mail.png" alt="mail email icon"></img>
                            <div><p>info@proviarg.com.ar</p></div>
                        </div>
                        <div className="contact_info_item">
                            <img src="icons/location.png" alt="location icon"></img>
                            <div><p>Colectora A012, <br/>Gdor. Ramón Saavedra 264<br/>San Lorenzo</p></div>
                        </div>
                    </div>

                    <div className="form_wrapper">
                        <Form />
                    </div>
                </div>
            </div>

            <footer>
                <a target="_blank" rel="noreferrer" href="https://www.facebook.com/Proviarg.industriales/"><img src="icons/facebook.png" alt="facebook icon"></img></a>
                <a target="_blank" rel="noreferrer" href="https://www.instagram.com/proviarg.industriales/"><img src="icons/instagram.png" alt="instagram icon"></img></a>
                <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/proviarg/"><img src="icons/linkedin.png" alt="linkedin icon"></img></a>
            </footer>

        </div>
    );
}


export default Homepage;