import { useState, useRef } from 'react';
import emailjs from 'emailjs-com';
import './form.css';

export default function Form() {

    const formSubmit = useRef();
    const form = useRef();

    const [userInput, setuserInput] = useState(
        { 
            name : "",
            company : "",
            email : "",
            number : "",
            message : "",
        }
    )

    // Email.JS Credentials
    const formConfig = {
        serviceID : "service_pbwud7b",
        templateID : "template_0b9x21s",
        userID : "user_eaTsGTkgQH39Xf8HA77o7",
    }

    const handleChange = (e) => {
        setuserInput({
            ...userInput,  
            [e.target.name] : e.target.value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if(validateEmail()){
            console.log("all good");
            sendEmail();
        } else {
            console.log("errors in form");
            return;
        }
    }

    const sendEmail = (e) => {
        emailjs.sendForm(formConfig.serviceID, formConfig.templateID, form.current, formConfig.userID)
        .then((result) => {
            console.log(result.text);
            // window.location.reload(false);
            alert("Gracias por contactarte");
        }, (error) => {
            console.log(error.text);
            alert("No se pudo enviar el mensaje");
        });
    }

    const validateEmail = () => {
        // Nombre
        if (userInput.name.length < 3) {
            console.log("Name error");
            return false;
        }

        // Apellido
        if (userInput.company.length < 3) {
            console.log("company error");
            return false;
        }

        // Mail
        if (userInput.email.length < 10) {
            console.log("Mail error");
            return false;
        }

        // Numero
        var numbers = /^[0-9]+$/;
        if(!userInput.number.match(numbers) || userInput.number.length < 8){
            console.log("Number error");
            return false;
        }

        return true;
    }

    return (
        <form id="contact-form" autoComplete="off" onSubmit={sendEmail} ref={form}>
                {/* Nombre */}
                <div className="field form_nombre" >
                    <label htmlFor="name">Nombre</label>
                    <input 
                    id="form_name" 
                    type="text" 
                    name="name" 
                    pattern="[A-Za-z0-9]+" 
                    maxLength="20"
                    value={userInput.name}
                    onChange={(e) => handleChange(e)}
                    />
                </div>

                {/* Apellido */}
                <div className="field form_empresa" >
                    <label htmlFor="company">Empresa</label>
                    <input 
                    id="company" 
                    type="text" 
                    name="company" 
                    pattern="[A-Za-z0-9]+" 
                    maxLength="20"
                    value={userInput.company}
                    onChange={(e) => handleChange(e)}
                    />
                </div>

                {/* Email */}
                <div className="field form_email">
                    <label htmlFor="email">Email</label>
                    <input 
                    id="form_email" 
                    type="email" 
                    name="email"
                    value={userInput.email}
                    onChange={(e) => handleChange(e)}
                    />
                </div>

                {/* Numero */}
                <div className="field form_numero">
                    <label htmlFor="number">Teléfono</label>
                    <input 
                    id="form_number" 
                    type="text" 
                    name="number" 
                    pattern="[0-9]+"
                    value={userInput.number}
                    onChange={(e) => handleChange(e)}
                    />
                </div>
            
                {/* Mensaje */}
                <div className="field message">
                    <label htmlFor="message">Mensaje</label>
                    <textarea 
                    maxLength="800"
                    name="message" 
                    value={userInput.message}
                    onChange={(e) => handleChange(e)}
                    ></textarea>
                </div>

                {/* Captcha */}
                <div className="g-recaptcha" data-sitekey="6Lf8UyEcAAAAAK5aXoYh3VHXakVFORJ63xvsKmUS"></div>

                {/* Form Submit */}
                <input ref={formSubmit} id="form_submit" className="form_submit" type="submit" name="submit" value="Enviar"/>

                {/* Dummy Submit */}
                <button type="button" className="dummy_submit" onClick={handleSubmit}>Enviar</button>
            </form>
    )
}